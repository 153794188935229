const siteLinkSearch = function () {
  const base = `${window.location.protocol}//${window.location.hostname}`;
  const locale = window.ACC.config.encodedContextPath;
  const website = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: `${base}/`,
    potentialAction: [
      {
        '@type': 'SearchAction',
        target: {
          '@type': 'EntryPoint',
          urlTemplate: `${base}${locale}/search/?text={search_term_string}`,
        },
        'query-input': 'required name=search_term_string',
      },
    ],
  };

  const scriptElement = document.createElement('script');
  scriptElement.setAttribute('type', 'application/ld+json');
  scriptElement.setAttribute('qaid', 'sitelink-search-jsonp');
  scriptElement.textContent = JSON.stringify(website);

  const head = document.getElementsByTagName('head')[0];
  head.appendChild(scriptElement);
};

export default siteLinkSearch;

// Application Shell
import {Shell} from '@/entryPoints/b2c/shell';

// Legacy Plugins
import '@/vendor/owl.carousel.min.js';
import '@/vendor/owl.carousel.plugins.js';
import '@/vendor/jquery.magnific-popup.min';

// Legacy Modules
import slider from '@/modules/slider';
import player from '@/modules/Players';
import videoBackground from '@/modules/VideoBackground';
import siteLinkSearch from '@/utils/richSearchResults/sitelink-search';

// Styling
import '@sassRoot/entryPoints/b2c/homepage.scss';

// Vue Components
import ProductCardItem from '@/components/containers/plp/ProductCardItem';
import {PdlCallout} from '@pedal/pdl-callout';
import ImageComponent from '@/components/ImageComponent';

class Homepage extends Shell {
  loadPlugins() {
    super.loadPlugins();
  }

  preLoadInits() {
    super.preLoadInits();

    window.addEventListener('init-vue-dynamic-content', () => {
      const domElements = document.querySelectorAll('[data-vue-dynamic-content]');
      if (!domElements || !domElements.length) {
        return;
      }

      domElements.forEach((el) => {
        new this.vue({
          el: el,
          components: {
            ImageComponent,
          },
        });
      });
    });
  }
}

const homepage = new Homepage({ProductCardItem, PdlCallout});
homepage.preLoadInits();
homepage.initializeVue();

// Legacy foundation initialization

$(window).on('load', () => {
  $(document).foundation();
  homepage.globalDocumentReadyInits();

  // Videos
  player();

  if ($('[data-bg-video]').length) {
    videoBackground($('[data-bg-video]'));
  }

  // setting up owl sliders
  const sliders = $('[data-owl-slider]');
  window.sliderRefs = [];
  sliders.each((i, s) => {
    window.sliderRefs.push(slider($(s)));
  });
});

$(window).on('load', () => {
  homepage.globalOnLoadInits();
  siteLinkSearch();
});
